import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Login() {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        if (login) {
            navigate("/");
        }
    }, []);
    const onSubmit = async (data) => {
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6 || !regex.test(data.username)) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự và không chứa kí tự đặc biệt"
            });
        }

        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu tối thiểu 6 kí tự"
            });
        }
        if (data.password.length < 6 || data.username.length < 6 || !regex.test(data.username)) {
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                username: data.username.toLowerCase(),
                password: data.password
            })
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    localStorage.setItem("user", res.data.data);
                    navigate("/");
                });
            })
            .catch((err) => setErr(err.response.data.message ? err.response.data.message : "Đã có lỗi xảy ra!"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <>
            <div className="login">
                <form className="form-lg" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "200px" }}>
                    <img alt="" src="/upload/login.png" className="logologin" />
                    <h1>Đăng Nhập</h1>
                    <div className="inputs">
                        <div className="input">
                            <input type="text" className="ip-lg" {...register("username", { required: true })} placeholder="Tên đăng nhập" />
                            {errors.username ? <p>{errors.username.message}</p> : null}
                        </div>
                        <div className="input">
                            <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Mật khẩu" />
                            <div onClick={toggleShowPassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</div>
                            {errors.password ? <p>{errors.password.message}</p> : null}
                        </div>
                    </div>
                    {err ? <p>{err}</p> : null}
                    <button className="btn-red-big" type="submit">
                        Đăng nhập
                    </button>
                    <p className="p-lg">
                        Chưa có tài khoản?{" "}
                        <Link className="bold" to="/register">
                            Đăng ký
                        </Link>
                    </p>
                    <p style={{ color: "#fff" }}>
                        Cần hỗ trợ ?{" "}
                        <Link className="a-lg" to="/service" style={{ color: "#fce406" }}>
                            Liên hệ CSKH
                        </Link>
                    </p>
                </form>
            </div>
        </>
    );
}
export default Login;
