import { useEffect, useState } from "react";
//import LiveChat from "react-livechat";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
import swal from "sweetalert";
import Header from "../components/Header";

function CSKH() {
    const [profile, setProfile] = useState(null);
    //const [isShow, setShow] = useState(false);
    const [slots, setSlots] = useState(null);
    const [isLoading, setLoading] = useState(false);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch((err) => localStorage.removeItem("user"));
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/getgamelist`, { product_type: "79", game_type: "RNG" })
            .then((res) => {
                setSlots(res.data.data[0]);
            })
            .catch((err) => {
                swal("Thông báo", "Trò chơi đang bảo trì", "info");
            });
    }, []);
    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    function launchGame(params) {
        if (profile === null) {
            window.location.href = "/login";
            return;
        }
        if (isLoading == true) return;
        const form = {
            ...params,
            platform: isMobileDevice == true ? "html5" : "html5-desktop"
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/launchgame`, form)
            .then((res) => {
                if (res.data.data.status == 0) {
                    window.location.href = res.data.data.game_url;
                    setLoading(false);
                } else {
                    setLoading(false);
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    return (
        <>
            {isLoading ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="main">
                <Header profile={profile} />
                <h1 className="title-h1">Game Slots</h1>
                <div className="gameapi-list">
                    {slots != null &&
                        slots.map((item) => (
                            <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: item.tcgGameCode })}>
                                <img src={`https://images.21947392.com/TCG_GAME_ICONS/BB/${item.tcgGameCode}.png`} alt={item.gameName} />
                                <div class="gameapi-name">{item.gameName}</div>
                            </div>
                        ))}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default CSKH;
