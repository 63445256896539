import Footer from "../../components/Footer/Footer";
import Header from "../components/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";

function TransferGame() {
    const [profile, setProfile] = useState(null);
    const [newMoney, setNewMoney] = useState(null);
    const [balance, setBalance] = useState(0);
    const [sanh, setSanh] = useState(null);
    const [type, setType] = useState(null);
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const { register, handleSubmit } = useForm();
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);

    const onSubmit = (data) => {
        if (load === false) {
            if (!sanh) {
                swal("Thông báo", "Vui lòng chọn sảnh", "warning");
                return;
            }
            const formData = {
                product_type: sanh,
                type: type,
                money: Number(data.money.replaceAll(".", "").replaceAll(",", ""))
            };
            if (!type) {
                swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
                return;
            }
            setLoad(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/casino/transfer`, formData)
                .then((res) => {
                    if (type == "2") {
                        swal("Thành công", `Chuyển quỹ ra khỏi sảnh ${sanh} thành công!`, "success").then(() => window.location.reload());
                    } else {
                        swal("Thành công", `Chuyển quỹ vào sảnh ${sanh} thành công!`, "success").then(() => window.location.reload());
                    }
                    axios
                        .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                        .then((res) => {
                            setProfile(res.data.data);
                        })
                        .catch((err) => localStorage.removeItem("user"));
                    setLoad(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                    setLoad(false);
                });
        }
    };
    const handleChange = (e) => {
        setLoading(true);
        setSanh(e.target.value);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/getbalance`, { product_type: e.target.value })
            .then((res) => {
                setBalance(res.data.data.balance);
                setLoading(false);
            })
            .catch((err) => swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error"));
    };
    const selectAll = (e) => {
        if (type == 1) {
            setNewMoney(profile.money > 0 && Math.floor(profile.money).toLocaleString("vi-VN"));
        } else if (type == 2) {
            balance > 0 && setNewMoney(Math.floor(balance).toLocaleString("vi-VN"));
        } else {
            swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
        }
    }
    return (
        <>
            {loading === true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="main">
                <Header profile={profile} />
                <h1 className="title-h1">Chuyển tiền vào sảnh</h1>
                <div className="content_profile" style={{ marginTop: "80px" }}>
                    <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                        <select onChange={handleChange} style={{ padding: "10px 20px", fontSize: "18px", width: "200px" }}>
                            <option hidden>Chọn game</option>
                            <option value="112">Live casino</option>
                            <option value="140">Bắn cá</option>
                            <option value="79">Nổ hũ</option>
                        </select>
                        {sanh && (
                            <>
                                <div style={{ color: "#000", fontSize: "16px", margin: "20px 0 10px" }}>Số dư: {balance?.toLocaleString("vi-VN")}</div>
                                <div style={{ display: "flex", color: "#ca5959", justifyContent: "space-around", marginBottom: "10px" }}>
                                    <div>
                                        <input type="radio" name="type_money" value="0" onChange={(e) => setType(1)} />
                                        Chuyển quỹ vào
                                    </div>
                                    <div>
                                        <input type="radio" name="type_money" value="1" onChange={(e) => setType(2)} />
                                        Chuyển quỹ ra
                                    </div>
                                </div>
                                {type == 1 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "14px" }}>
                                        Số tiền nhận được trong game: <b>{(Number(newMoney.replaceAll(".", "")) / 1000).toLocaleString("vi-VN")}</b>
                                    </div>
                                )}
                                {type == 2 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "14px" }}>
                                        Số tiền nhận vào ví chính: <b>{(Number(newMoney.replaceAll(".", "")) * 1000).toLocaleString("vi-VN")}</b>
                                    </div>
                                )}
                                <div className="relative-input">
                                    <input
                                        className="ipadd"
                                        type="text"
                                        {...register("money", { required: true })}
                                        placeholder="Nhập số tiền"
                                        value={newMoney}
                                        onClick={() => setNewMoney(null)}
                                        onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    />
                                    <div className="absolute-input" onClick={selectAll}>
                                        Tất cả
                                    </div>
                                </div>
                                <button type="submit" className={load === true ? "btn-submit loader" : "btn-submit"}>
                                    Xác nhận
                                </button>
                            </>
                        )}
                    </form>
                </div>

                <Footer />
            </div>
        </>
    );
}
export default TransferGame;
