import { Doughnut } from "react-chartjs-2";
import { Box, Card, CardContent, CardHeader, Divider, Button, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import "react-datepicker/dist/react-datepicker.css";

export const TrafficByDevice = (props) => {
    const [dataTable, setData] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [load, setLoad] = useState(false);
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        if (load == false && props.data != undefined) {
            setLoad(true);
            setData({
                datasets: [
                    {
                        label: " ",
                        data: [props?.data?.tongcuoc, props?.data?.tongthang],
                        backgroundColor: ["#3F51B5", "#e53935", "#FB8C00"],
                        borderWidth: 8,
                        borderColor: "#FFFFFF",
                        hoverBorderColor: "#FFFFFF"
                    }
                ],
                labels: ["Tổng cược", "Tổng thắng"]
            });
        }
    }, [{ ...props }]);
    useEffect(() => {
        if (load == true) {
            setLoading(true);
            axios.get(`${process.env.REACT_APP_API_URL}/statistic/getbydayadmin?dateStart=${startDate}&endDate=${endDate}`).then((res) => {
                setData({
                    datasets: [
                        {
                            label: " ",
                            data: [res.data.data.tongcuoc, res.data.data.tongthang],
                            backgroundColor: ["#3F51B5", "#e53935", "#FB8C00"],
                            borderWidth: 8,
                            borderColor: "#FFFFFF",
                            hoverBorderColor: "#FFFFFF"
                        }
                    ],
                    labels: ["Tổng cược", "Tổng thắng"]
                });
                setLoading(false);
            }).catch((err) => setLoading(false));
        }
    }, [endDate, startDate, load]);
    const theme = useTheme();

    const data = {
        datasets: [
            {
                label: " ",
                data: [0, 0, 0],
                backgroundColor: ["#3F51B5", "#e53935", "#FB8C00"],
                borderWidth: 8,
                borderColor: "#FFFFFF",
                hoverBorderColor: "#FFFFFF"
            }
        ],
        labels: ["Tổng cược", "Tổng thắng"]
    };

    const options = {
        animation: false,
        cutoutPercentage: 80,
        layout: { padding: 0 },
        legend: {
            display: false
        },
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
            display: false,
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 0,
            padding: 10,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: "index",
            titleFontColor: theme.palette.text.primary
        }
    };

    const devices = [
        {
            title: "Tổng cược",
            value: dataTable ? 100 : 0,
            color: "#E53935"
        },
        {
            title: "Tổng thắng",
            value: dataTable ? ((dataTable.datasets[0].data[1] / dataTable.datasets[0].data[0]) * 100).toFixed(2) : 0,
            color: "#FB8C00"
        }
    ];

    return (
        <Card {...props}>
            {loading && (
                <div className="loading" style={{maxWidth:"100vw"}}>
                    <div className="loader"></div>
                </div>
            )}
            <CardHeader title="Tổng tiền chơi" />
            <Divider />
            <CardContent>
                <Box
                    sx={{
                        height: 300,
                        position: "relative"
                    }}>
                    {dataTable ? <Doughnut data={dataTable} options={options} /> : <Doughnut data={data} options={options} />}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        pt: 2
                    }}>
                    {devices.map(({ color, title, value }) => (
                        <Box
                            key={title}
                            sx={{
                                p: 1,
                                textAlign: "center",
                                alignItems: "center",
                                display: "flex"
                            }}>
                            <Typography color="textPrimary" variant="body1" sx={{ marginRight: "3px" }}>
                                {title}
                            </Typography>
                            <Typography style={{ color }} variant="h5">
                                {value}%
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        pt: 2
                    }}>
                    <div>
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "100px" }}>Từ</div>
                            <DatePicker
                                maxDate={new Date()}
                                selected={startDate}
                                onChange={(date) => {
                                    setStartDate(date);
                                    setLoad(true);
                                }}
                            />
                        </div>
                        <br />
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "100px" }}>Đến</div>
                            <DatePicker
                                maxDate={new Date()}
                                selected={endDate}
                                onChange={(date) => {
                                    setEndDate(date);
                                    setLoad(true);
                                }}
                            />
                        </div>
                    </div>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 2
                    }}>
                    <Button
                        className="button-admin"
                        color="primary"
                        endIcon={<ArrowRightIcon fontSize="small" />}
                        size="small"
                        onClick={() => {
                            setStartDate(null);
                            setEndDate(new Date());
                            setData({
                                datasets: [
                                    {
                                        label: " ",
                                        data: [props?.data?.tongcuoc, props?.data?.tongthang],
                                        backgroundColor: ["#3F51B5", "#e53935", "#FB8C00"],
                                        borderWidth: 8,
                                        borderColor: "#FFFFFF",
                                        hoverBorderColor: "#FFFFFF"
                                    }
                                ],
                                labels: ["Tổng cược", "Tổng thắng"]
                            });
                        }}>
                        Xem tất cả thời gian
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};
