import { Box, Container } from "@mui/material";
import swal from "sweetalert";
import axios from "axios";
import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import "./account.css";
import { theme } from "../../theme";
import { useEffect, useState } from "react";

function Setting() {
    const [setting, setSetting] = useState();
    const [load, setLoad] = useState(true);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        if (load === true) {
            axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
                setSetting(res.data.data[0]);
                setLoad(false);
            });
        }
    }, [load]);
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            id: setting?._id,
            aff: Number(e.target.aff.value),
            kmnapdau: Number(e.target.kmnapdau.value),
            doiben: Number(e.target.doiben.value),

            xucsac5p: Number(e.target.xucsac5p.value),
            xucsac3p: Number(e.target.xucsac5p.value),
            haitrung3: Number(e.target.haitrung3.value),
            haitrung5: Number(e.target.haitrung5.value),

            batrung3: Number(e.target.batrung3.value),
            batrung5: Number(e.target.batrung5.value),

            lothuong: Number(e.target.lothuong.value),
            lo3: Number(e.target.lo3.value),
            bacang: Number(e.target.bacang.value),
            de: Number(e.target.de.value),
            loxien2: Number(e.target.loxien2.value),
            loxien3: Number(e.target.loxien3.value),
            loxien4: Number(e.target.loxien4.value),
            truotxien4: Number(e.target.truotxien4.value),
            truotxien8: Number(e.target.truotxien8.value),
            truotxien10: Number(e.target.truotxien10.value),
            boncangdacbiet: Number(e.target.boncangdacbiet.value),

            mblothuong: Number(e.target.mblothuong.value),
            mblo3: Number(e.target.mblo3.value),
            mbbacang: Number(e.target.mbbacang.value),
            mbde: Number(e.target.mbde.value),
            mbloxien2: Number(e.target.mbloxien2.value),
            mbloxien3: Number(e.target.mbloxien3.value),
            mbloxien4: Number(e.target.mbloxien4.value),
            mbtruotxien4: Number(e.target.mbtruotxien4.value),
            mbtruotxien8: Number(e.target.mbtruotxien8.value),
            mbtruotxien10: Number(e.target.mbtruotxien10.value),
            mbboncangdacbiet: Number(e.target.mbboncangdacbiet.value),

            mtlothuong: Number(e.target.mtlothuong.value),
            mtlo3: Number(e.target.mtlo3.value),
            mtbacang: Number(e.target.mtbacang.value),
            mtde: Number(e.target.mtde.value),
            mtloxien2: Number(e.target.mtloxien2.value),
            mtloxien3: Number(e.target.mtloxien3.value),
            mtloxien4: Number(e.target.mtloxien4.value),
            mttruotxien4: Number(e.target.mttruotxien4.value),
            mttruotxien8: Number(e.target.mttruotxien8.value),
            mttruotxien10: Number(e.target.mttruotxien10.value),
            mtboncangdacbiet: Number(e.target.mtboncangdacbiet.value),

            mnlothuong: Number(e.target.mnlothuong.value),
            mnlo3: Number(e.target.mnlo3.value),
            mnbacang: Number(e.target.mnbacang.value),
            mnde: Number(e.target.mnde.value),
            mnloxien2: Number(e.target.mnloxien2.value),
            mnloxien3: Number(e.target.mnloxien3.value),
            mnloxien4: Number(e.target.mnloxien4.value),
            mntruotxien4: Number(e.target.mntruotxien4.value),
            mntruotxien8: Number(e.target.mntruotxien8.value),
            mntruotxien10: Number(e.target.mntruotxien10.value),
            mnboncangdacbiet: Number(e.target.mnboncangdacbiet.value),

            open: Number(e.target.opens.value),
            tx1: Number(e.target.tx1.value),
            tx3: Number(e.target.tx3.value),
            tx5: Number(e.target.tx5.value),
            le: Number(e.target.le.value),
            chan: Number(e.target.chan.value),
            f3trang: Number(e.target.f3trang.value),
            f3den: Number(e.target.f3den.value),
            ftrang: Number(e.target.ftrang.value),
            fden: Number(e.target.fden.value),
            greedy: {
                open: Number(e.target.open.value),
                auto: Number(e.target.auto.value),
                tyle1: Number(e.target.tyle1.value),
                tyle2: Number(e.target.tyle2.value),
                tyle3: Number(e.target.tyle3.value),
                tyle4: Number(e.target.tyle4.value),
                tyle5: Number(e.target.tyle5.value),
                tyle6: Number(e.target.tyle6.value),
                tyle7: Number(e.target.tyle7.value),
                tyle8: Number(e.target.tyle8.value),
                //tyle9: Number(e.target.tyle9.value),
                //tyle10: Number(e.target.tyle10.value),
                thuong1: Number(e.target.thuong1.value),
                thuong2: Number(e.target.thuong2.value),
                thuong3: Number(e.target.thuong3.value),
                thuong4: Number(e.target.thuong4.value),
                thuong5: Number(e.target.thuong5.value),
                thuong6: Number(e.target.thuong6.value),
                thuong7: Number(e.target.thuong7.value),
                thuong8: Number(e.target.thuong8.value),
                thuong9: Number(e.target.thuong9.value),
                thuong10: Number(e.target.thuong10.value)
                //nosalad: Number(e.target.nosalad.value),
                //nopizza: Number(e.target.nopizza.value)
            }
        };
        console.log(formData);
        axios
            .put(`${process.env.REACT_APP_API_URL}/setting/update`, formData)
            .then((res) => {
                setLoad(true);
                swal("Sửa thông tin trò chơi thành công!");
            })
            .catch((res) => setLoad(true));
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 8
                            }}>
                            <Container maxWidth={false}>
                                <div style={{ fontSize: "25px", fontWeight: 700 }}>Cài đặt trả thưởng</div>
                                <div style={{ marginTop: "20px" }}>
                                    <form className="setting" onSubmit={handleSubmit}>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Greedy
                                        </h3>
                                        <span style={{ display: "inline-block", margin: "0 10px 0 10px" }}>Auto chỉnh kết quả: </span>
                                        <input
                                            defaultValue={setting?.greedy?.auto}
                                            type="number"
                                            name="auto"
                                            step="1"
                                            id="auto"
                                            min="0"
                                            max="1"
                                            style={{ width: "50px", position: "relative", top: "-5px" }}
                                        />
                                        <span style={{ display: "inline-block", margin: "0 10px 0 10px" }}>Bật tắt game: </span>
                                        <input
                                            defaultValue={setting?.greedy?.open}
                                            type="number"
                                            name="open"
                                            step="1"
                                            id="open"
                                            min="0"
                                            max="1"
                                            style={{ width: "50px", position: "relative", top: "-5px" }}
                                        />
                                        <span style={{ display: "inline-block", margin: "0 10px 0 10px", color: "red" }}>(1 là bật 0 là tắt) </span>
                                        <p style={{ width: "100%", textAlign: "left", margin: "0 10px 10px", color: "#06cf3c" }}>
                                            Tổng tỉ lệ trúng hiện tại:{" "}
                                            {setting?.greedy?.tyle1 +
                                                setting?.greedy?.tyle2 +
                                                setting?.greedy?.tyle3 +
                                                setting?.greedy?.tyle4 +
                                                setting?.greedy?.tyle5 +
                                                setting?.greedy?.tyle6 +
                                                setting?.greedy?.tyle7 +
                                                setting?.greedy?.tyle8}
                                        </p>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng bánh</label>
                                                <input defaultValue={setting?.greedy?.tyle1} type="number" name="tyle1" step="any" id="tyle1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Thưởng bánh</label>
                                                <input defaultValue={setting?.greedy?.thuong1} type="number" name="thuong1" step="any" id="thuong1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng xiên</label>
                                                <input defaultValue={setting?.greedy?.tyle2} type="number" name="tyle2" step="any" id="tyle2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Thưởng xiên</label>
                                                <input defaultValue={setting?.greedy?.thuong2} type="number" name="thuong2" step="any" id="thuong2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng đùi</label>
                                                <input defaultValue={setting?.greedy?.tyle3} type="number" name="tyle3" step="any" id="tyle3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Thưởng đùi</label>
                                                <input defaultValue={setting?.greedy?.thuong3} type="number" name="thuong3" step="any" id="thuong3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng thịt</label>
                                                <input defaultValue={setting?.greedy?.tyle4} type="number" name="tyle4" step="any" id="tyle4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Thưởng thịt</label>
                                                <input defaultValue={setting?.greedy?.thuong4} type="number" name="thuong4" step="any" id="thuong4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng cà rốt</label>
                                                <input defaultValue={setting?.greedy?.tyle5} type="number" name="tyle5" step="any" id="tyle5" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Thưởng cà rốt</label>
                                                <input defaultValue={setting?.greedy?.thuong5} type="number" name="thuong5" step="any" id="thuong5" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng bắp ngô</label>
                                                <input defaultValue={setting?.greedy?.tyle6} type="number" name="tyle6" step="any" id="tyle6" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Thưởng bắp ngô</label>
                                                <input defaultValue={setting?.greedy?.thuong6} type="number" name="thuong6" step="any" id="thuong6" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng rau cải</label>
                                                <input defaultValue={setting?.greedy?.tyle7} type="number" name="tyle7" step="any" id="tyle7" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Thưởng rau cải</label>
                                                <input defaultValue={setting?.greedy?.thuong7} type="number" name="thuong7" step="any" id="thuong7" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng cà chua</label>
                                                <input defaultValue={setting?.greedy?.tyle8} type="number" name="tyle8" step="any" id="tyle8" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Thưởng cà chua</label>
                                                <input defaultValue={setting?.greedy?.thuong8} type="number" name="thuong8" step="any" id="thuong8" className="input_setting" />
                                            </div>
                                        </div>
                                        {/*<div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng rổ rau</label>
                                                <input defaultValue={setting?.greedy?.tyle9} type="number" name="tyle9" step="any" id="tyle9" className="input_setting" />
                                            </div>
                                        </div>*/}
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Thưởng rổ rau</label>
                                                <input defaultValue={setting?.greedy?.thuong9} type="number" name="thuong9" step="any" id="thuong9" className="input_setting" />
                                            </div>
                                        </div>
                                        {/*<div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng đĩa thịt</label>
                                                <input defaultValue={setting?.greedy?.tyle10} type="number" name="tyle10" step="any" id="tyle10" className="input_setting" />
                                            </div>
                                        </div>*/}
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label>Thưởng đĩa thịt</label>
                                                <input defaultValue={setting?.greedy?.thuong10} type="number" name="thuong10" step="any" id="thuong10" className="input_setting" />
                                            </div>
                                        </div>
                                        {/*<div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label style={{ color: "red" }}>Tỉ lệ nổ salad</label>
                                                <input defaultValue={setting?.greedy?.nosalad} type="number" name="nosalad" step="any" id="nosalad" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ width: "50%" }}>
                                            <div className="form_group">
                                                <label style={{ color: "red" }}>Tỉ lệ nổ pizza</label>
                                                <input defaultValue={setting?.greedy?.nopizza} type="number" name="nopizza" step="any" id="nopizza" className="input_setting" />
                                            </div>
                                        </div>*/}
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Keno
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đôi bên</label>
                                                <input defaultValue={setting?.doiben} type="number" name="doiben" step="any" id="doiben" className="input_setting" />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Xúc sắc
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Súc xắc 3p</label>
                                                <input defaultValue={setting?.xucsac3p} type="number" name="xucsac3p" step="any" id="xucsac3p" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Hai số trùng Xúc sắc 3p</label>
                                                <input defaultValue={setting?.haitrung3} type="number" name="haitrung3" step="any" id="haitrung3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Ba số trùng Xúc sắc 3p</label>
                                                <input defaultValue={setting?.batrung3} type="number" name="batrung3" step="any" id="batrung3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Súc xắc 5p</label>
                                                <input defaultValue={setting?.xucsac5p} type="number" name="xucsac5p" step="any" id="xucsac5p" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Hai số trùng Xúc sắc 5p</label>
                                                <input defaultValue={setting?.haitrung5} type="number" name="haitrung5" step="any" id="haitrung5" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Ba số trùng Xúc sắc 5p</label>
                                                <input defaultValue={setting?.batrung5} type="number" name="batrung5" step="any" id="batrung5" className="input_setting" />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Xổ số nhanh
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2</label>
                                                <input defaultValue={setting?.lothuong} type="number" name="lothuong" step="any" id="lothuong" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 3</label>
                                                <input defaultValue={setting?.lo3} type="number" name="lo3" step="any" id="lo3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề 3 càng</label>
                                                <input defaultValue={setting?.bacang} type="number" name="bacang" step="any" id="bacang" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề</label>
                                                <input defaultValue={setting?.de} type="number" step="any" name="de" id="de" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 2</label>
                                                <input defaultValue={setting?.loxien2} type="number" name="loxien2" step="any" id="loxien2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 3</label>
                                                <input defaultValue={setting?.loxien3} type="number" name="loxien3" step="any" id="loxien3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 4</label>
                                                <input defaultValue={setting?.loxien4} type="number" name="loxien4" step="any" id="loxien4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 4</label>
                                                <input defaultValue={setting?.truotxien4} type="number" name="truotxien4" step="any" id="truotxien4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 8</label>
                                                <input defaultValue={setting?.truotxien8} type="number" name="truotxien8" step="any" id="truotxien8" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 10</label>
                                                <input defaultValue={setting?.truotxien10} type="number" name="truotxien10" step="any" id="truotxien10" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>4 càng đặc biệt</label>
                                                <input
                                                    defaultValue={setting?.boncangdacbiet}
                                                    type="number"
                                                    name="boncangdacbiet"
                                                    step="any"
                                                    id="boncangdacbiet"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            XSMB
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2</label>
                                                <input defaultValue={setting?.mblothuong} type="number" name="mblothuong" step="any" id="mblothuong" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 3</label>
                                                <input defaultValue={setting?.mblo3} type="number" name="mblo3" step="any" id="mblo3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề 3 càng</label>
                                                <input defaultValue={setting?.mbbacang} type="number" name="mbbacang" step="any" id="mbbacang" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề</label>
                                                <input defaultValue={setting?.mbde} type="number" step="any" name="mbde" id="mbde" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 2</label>
                                                <input defaultValue={setting?.mbloxien2} type="number" name="mbloxien2" step="any" id="mbloxien2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 3</label>
                                                <input defaultValue={setting?.mbloxien3} type="number" name="mbloxien3" step="any" id="mbloxien3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 4</label>
                                                <input defaultValue={setting?.mbloxien4} type="number" name="mbloxien4" step="any" id="mbloxien4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 4</label>
                                                <input
                                                    defaultValue={setting?.mbtruotxien4}
                                                    type="number"
                                                    name="mbtruotxien4"
                                                    step="any"
                                                    id="mbtruotxien4"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 8</label>
                                                <input
                                                    defaultValue={setting?.mbtruotxien8}
                                                    type="number"
                                                    name="mbtruotxien8"
                                                    step="any"
                                                    id="mbtruotxien8"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 10</label>
                                                <input
                                                    defaultValue={setting?.mbtruotxien10}
                                                    type="number"
                                                    name="mbtruotxien10"
                                                    step="any"
                                                    id="mbtruotxien10"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>4 càng đặc biệt</label>
                                                <input
                                                    defaultValue={setting?.mbboncangdacbiet}
                                                    type="number"
                                                    name="mbboncangdacbiet"
                                                    step="any"
                                                    id="mbboncangdacbiet"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            XSMT
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2</label>
                                                <input defaultValue={setting?.mtlothuong} type="number" name="mtlothuong" step="any" id="mtlothuong" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 3</label>
                                                <input defaultValue={setting?.mtlo3} type="number" name="mtlo3" step="any" id="mtlo3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề 3 càng</label>
                                                <input defaultValue={setting?.mtbacang} type="number" name="mtbacang" step="any" id="mtbacang" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề</label>
                                                <input defaultValue={setting?.mtde} type="number" step="any" name="mtde" id="mtde" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 2</label>
                                                <input defaultValue={setting?.mtloxien2} type="number" name="mtloxien2" step="any" id="mtloxien2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 3</label>
                                                <input defaultValue={setting?.mtloxien3} type="number" name="mtloxien3" step="any" id="mtloxien3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 4</label>
                                                <input defaultValue={setting?.mtloxien4} type="number" name="mtloxien4" step="any" id="mtloxien4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 4</label>
                                                <input
                                                    defaultValue={setting?.mttruotxien4}
                                                    type="number"
                                                    name="mttruotxien4"
                                                    step="any"
                                                    id="mttruotxien4"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 8</label>
                                                <input
                                                    defaultValue={setting?.mttruotxien8}
                                                    type="number"
                                                    name="mttruotxien8"
                                                    step="any"
                                                    id="mttruotxien8"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 10</label>
                                                <input
                                                    defaultValue={setting?.mttruotxien10}
                                                    type="number"
                                                    name="mttruotxien10"
                                                    step="any"
                                                    id="mttruotxien10"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>4 càng đặc biệt</label>
                                                <input
                                                    defaultValue={setting?.mtboncangdacbiet}
                                                    type="number"
                                                    name="mtboncangdacbiet"
                                                    step="any"
                                                    id="mtboncangdacbiet"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            XSMN
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2</label>
                                                <input defaultValue={setting?.mnlothuong} type="number" name="mnlothuong" step="any" id="mnlothuong" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 3</label>
                                                <input defaultValue={setting?.mnlo3} type="number" name="mnlo3" step="any" id="mnlo3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề 3 càng</label>
                                                <input defaultValue={setting?.mnbacang} type="number" name="mnbacang" step="any" id="mnbacang" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề</label>
                                                <input defaultValue={setting?.mnde} type="number" step="any" name="mnde" id="mnde" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 2</label>
                                                <input defaultValue={setting?.mnloxien2} type="number" name="mnloxien2" step="any" id="mnloxien2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 3</label>
                                                <input defaultValue={setting?.mnloxien3} type="number" name="mnloxien3" step="any" id="mnloxien3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 4</label>
                                                <input defaultValue={setting?.mnloxien4} type="number" name="mnloxien4" step="any" id="mnloxien4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 4</label>
                                                <input
                                                    defaultValue={setting?.mntruotxien4}
                                                    type="number"
                                                    name="mntruotxien4"
                                                    step="any"
                                                    id="mntruotxien4"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 8</label>
                                                <input
                                                    defaultValue={setting?.mntruotxien8}
                                                    type="number"
                                                    name="mntruotxien8"
                                                    step="any"
                                                    id="mntruotxien8"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 10</label>
                                                <input
                                                    defaultValue={setting?.mntruotxien10}
                                                    type="number"
                                                    name="mntruotxien10"
                                                    step="any"
                                                    id="mntruotxien10"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>4 càng đặc biệt</label>
                                                <input
                                                    defaultValue={setting?.mnboncangdacbiet}
                                                    type="number"
                                                    name="mnboncangdacbiet"
                                                    step="any"
                                                    id="mnboncangdacbiet"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Tài xỉu
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Tài xỉu 1p</label>
                                                <input defaultValue={setting?.tx1} type="number" name="tx1" step="any" id="tx1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Tài xỉu 3p</label>
                                                <input defaultValue={setting?.tx3} type="number" name="tx3" step="any" id="tx3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Tài xỉu 5p</label>
                                                <input defaultValue={setting?.tx5} type="number" name="tx5" step="any" id="tx5" className="input_setting" />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            LỘC PHÁT
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Chẳn</label>
                                                <input defaultValue={setting?.chan} type="number" name="chan" step="any" id="chan" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lẻ</label>
                                                <input defaultValue={setting?.le} type="number" name="le" step="any" id="le" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>4 trắng</label>
                                                <input defaultValue={setting?.ftrang} type="number" name="ftrang" step="any" id="ftrang" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>4 đen</label>
                                                <input defaultValue={setting?.fden} type="number" name="fden" step="any" id="fden" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 trắng 1 đen</label>
                                                <input defaultValue={setting?.f3trang} type="number" name="f3trang" step="any" id="f3trang" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 đen 1 trắng</label>
                                                <input defaultValue={setting?.f3den} type="number" name="f3den" step="any" id="f3den" className="input_setting" />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Khác
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Hoa hồng đại lý mặc định</label>
                                                <input defaultValue={setting?.aff} type="number" name="aff" step="any" id="aff" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Khuyến mãi nạp đầu (%)</label>
                                                <input defaultValue={setting?.kmnapdau} type="number" name="kmnapdau" step="any" id="kmnapdau" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ display: "none" }}>
                                            <div className="form_group">
                                                <input defaultValue={setting?.open} type="number" name="opens" step="any" id="opens" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ clear: "both", width: "100%" }}>
                                            <button type="submit" className="btn_setting">
                                                Lưu
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Container>
                        </Box>
                    }
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}

export default Setting;
