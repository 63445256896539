import { useEffect, useState } from "react";
//import LiveChat from "react-livechat";
import Footer from "../../components/Footer/Footer";
import Header from "../components/Header";
import axios from "axios";
import swal from "sweetalert";

function Invite() {
    const [profile, setProfile] = useState(null);
    const [isShow, setShow] = useState(false);
    const [profit, setProfit] = useState(null);
    const [daily, setDaily] = useState(null);
    const [popup, setPopup] = useState(null);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    /*const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);*/
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch((err) => localStorage.removeItem("user"));
        axios.get(`${process.env.REACT_APP_API_URL}/profit/get`, {}).then((res) => {
            setProfit(res.data.data[0]);
        });
        axios.post(`${process.env.REACT_APP_API_URL}/auth/getCustomer`, { code: "" }).then((res) => {
            setDaily(res.data.data);
        });
    }, []);
    const handleCopyClick = (text) => {
        const textToCopy = text;
        const tempInput = document.createElement("input");
        tempInput.value = textToCopy;
        document.body.appendChild(tempInput);
        tempInput.select();
        tempInput.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        swal("Thành công", "Copy mã mời thành công", "success");
    };
    const thongkeAff = (username) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/thongkeaff`, { username })
            .then((res) => {
                setPopup(res.data.data);
                setShow(true);
            })
            .catch((err) => swal("Chưa có dữ liệu", "Người chơi này chưa tham gia trò chơi nào", "info"));
    };
    const chuyenDoi = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/chuyendoiaff`, {})
            .then((res) => {
                swal("Thành công", "Đã chuyển đổi toàn bộ hoa hồng thành số dư tài khoản", "success");
            })
            .catch((err) => swal("Thông báo", err.response.data.message != "" ? err.response.data.message : "Đã có lỗi xảy ra!", "error"));
    };
    return (
        <>
            {/*isLoading ? (
				<div className="loading">
					<div className="loader"></div>
				</div>
			) : null*/}
            <div className="main">
                <Header profile={profile} />
                <h1 className="title-h1">Mời Bạn Bè</h1>
                <div className="text_choose_center huongdan" style={{ position: "relative" }}>
                    <div className="title" style={{ margin: "0.2rem 0 0.4rem" }}>
                        Giới thiệu bạn bè nhận quà liền tay
                    </div>
                    <ul>
                        <li>Nhận thưởng hoa hồng mỗi khi người chơi được bạn giới thiệu tham gia bất kì trò chơi nào.</li>
                        <li>Số tiền hoa hồng dùng để chuyển đổi sang số dư tài khoản.</li>
                        <li>Số tiền hoa hồng tối thiểu để chuyển đổi sang số dư là 10.000</li>
                    </ul>
                </div>
                <div className="invite">
                    <div className="text-invite">
                        <h3>
                            Hoa hồng mini game: <b>{profile?.hoahong ? profile?.hoahong : 1}%</b>
                        </h3>
                        {profile?.hoahonglive > 0 && (
                            <h3>
                                Hoa hồng live casino: <b>{profile?.hoahonglive ? profile?.hoahonglive : 1}%</b>
                            </h3>
                        )}
                        {profile?.hoahongslots > 0 && (
                            <h3>
                                Hoa hồng nổ hũ & bắn cá: <b>{profile?.hoahongslots ? profile?.hoahongslots : 1}%</b>
                            </h3>
                        )}
                        <div>Mã mời của bạn</div>
                        <h4 onClick={() => handleCopyClick(profile?.code)} style={{ cursor: "pointer" }}>
                            {profile?.code}
                            <span className="copy-invite">COPY</span>
                        </h4>
                    </div>
                </div>
                <div className="chuyendoihoahong lottery-menu-mid">
                    <div>
                        <img alt="" src={require("../../img/invitation_bg.png")} width="80" />
                    </div>
                    <div>
                        <h4>Hoa hồng hiện tại:</h4>
                        <h3>{Number(profile?.moneyaff?.toFixed(0))?.toLocaleString("vi-VN")}</h3>
                    </div>
                    <div>
                        <button className="badge green large" onClick={chuyenDoi}>
                            Chuyển đổi
                        </button>
                    </div>
                </div>
                <div className="baocaodaily">
                    <h3>Báo cáo đại lý</h3>
                    <div className="text_choose_center" style={{ padding: "0", overflow: "hidden" }}>
                        {daily ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Tài khoản</th>
                                        <th>Số tiền cược</th>
                                        <th>Hoa hồng</th>
                                        <th>Nhật ký</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {daily.map((user) => (
                                        <>
                                            {user?.username != profile?.username && (
                                                <tr>
                                                    <td>
                                                        <a>{user?.username}</a>
                                                        <br />
                                                        ID: {user?.iduser}
                                                    </td>
                                                    <td>{user?.totalbet?.toLocaleString("vi-VN")}</td>
                                                    <td>{Number(user?.moneyforaff?.toFixed(0))?.toLocaleString("vi-VN")}</td>
                                                    <td>
                                                        <span className="badge blue" onClick={() => thongkeAff(user?.username)}>
                                                            Kiểm tra
                                                        </span>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div style={{ padding: "20px 0" }}>Chưa có người dùng nào</div>
                        )}
                    </div>
                </div>
            </div>
            {isShow === true ? (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-header">
                            Lịch sử của <b>{popup[0]?.username}</b>
                        </div>
                        <div className="popup-content content-history" style={{ padding: "0" }}>
                            {popup &&
                                popup.map((item) => (
                                    <>
                                        <div className="item_inner">
                                            <div className="item_history">
                                                <div className="title_item_history">
                                                    <span className="sanh">
                                                        {item.sanh === "3 phút" ? "Keno 3p" : item.sanh === "5 phút" ? "Keno 5p" : item.sanh === "1 phút" ? "Keno 1p" : item.sanh}
                                                    </span>
                                                </div>
                                                <div className="id_history_sanh">Số tiền cược: {Number(item.money).toLocaleString("vi-VN")}</div>
                                                <div className="id_history_sanh">Hoa hồng nhận được: {Number(item.hoahong).toLocaleString("vi-VN")}</div>
                                            </div>
                                            <div className="money_history">
                                                <span className="money">{Number(item.money).toLocaleString("vi-VN")}</span>
                                                <div className="time_choose">{formatDate(new Date(item.createdAt))}</div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                        </div>
                        <button
                            onClick={() => setShow(false)}
                            className="popup-close"
                            style={{
                                background: "#00b977",
                                boxShadow: "none",
                                textShadow: "none",
                                fontSize: "18px"
                            }}>
                            Đóng
                        </button>
                    </div>
                </div>
            ) : null}
            <Footer />
        </>
    );
}

export default Invite;
