import axios from "axios";
import { useEffect, useState } from "react";
import swal from "sweetalert";
//import Footer from "../../../components/Footer/Footer";
//import { GetNameChoose } from "../../../funcUtils";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import RestoreIcon from "@mui/icons-material/Restore";
import "./style.css";
import useWebSocket, { ReadyState } from "react-use-websocket";

function Greedy() {
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(process.env.REACT_APP_API_SOCKET);
    const [isVisible, setVisible] = useState(null);
    const [bet, setBet] = useState(null);
    const [profile, setProfile] = useState(null);
    const [historyGame, setHistoryGame] = useState(null);
    const [second, setSecond] = useState(0);
    const [minute, setMinute] = useState(1);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [update, setUpdate] = useState(0);
    const date = new Date();
    const currentMinute = date.getMinutes();
    const currentSecond = date.getSeconds();
    const [KQ, setKQ] = useState(null);
    const [WIN, setWIN] = useState(null);
    const [LOSE, setLOSE] = useState(null);
    const [total, setTotal] = useState(null);
    const [setting, setSetting] = useState(null);
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    const [newMoney, setNewMoney] = useState(0);
    const [activeOption, setActiveOption] = useState(null);
    const [active, setActive] = useState(null);
    const [counters, setCounters] = useState({ s0: 0, s1: 0, s2: 0, s3: 0, s4: 0, s5: 0, s6: 0, s7: 0, s8: 0, s9: 0 });
    const [bxh, setBXH] = useState(null);
    const [viewBXH, setViewBXH] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (readyState == 2 || readyState == 3) {
            if (profile) {
                window.location.href = "/";
            } else {
                window.location.href = "/login";
            }
        }
    }, [readyState]);
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const getProfile = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            setProfile(res.data.data);
        });
    };
    useEffect(() => {
        getProfile();
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            setSetting(res.data.data[0]);
        });
        axios.get(`${process.env.REACT_APP_API_URL}/greedy/get`).then((res) => {
            setBet(res.data.data);
            setDulieunhap(new Date(res.data.data.createdAt));
            setStart(true);
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/greedy/getallbet`, {})
            .then((res) => {
                setTotal(res.data.data);
            })
            .catch(() => setTotal(null));
        axios.get(`${process.env.REACT_APP_API_URL}/notification/getnotifi`, {}).then((res) => {
            setVisible({
                money: res.data.data[0]?.money.toLocaleString("vi-VN"),
                id: res.data.data[0]._id
            });
        });
        axios.get(`${process.env.REACT_APP_API_URL}/greedy/bxh`, {}).then((res) => {
            setBXH(res.data.data);
        });
        getHistoryBet();
    }, []);
    useEffect(() => {
        const timer = setInterval(() => {
            if (Math.floor(60 - (new Date() - dulieunhap) / 1000) < 0) {
                getProfile();
                axios.get(`${process.env.REACT_APP_API_URL}/greedy/get`).then((res) => {
                    setBet(res.data.data);
                    setDulieunhap(new Date(res.data.data.createdAt));
                });
                axios
                    .get(`${process.env.REACT_APP_API_URL}/greedy/getallbet`, {})
                    .then((res) => {
                        setTotal(res.data.data);
                    })
                    .catch(() => setTotal(null));
                axios.get(`${process.env.REACT_APP_API_URL}/notification/getnotifi`, {}).then((res) => {
                    setVisible({
                        money: res.data.data[0]?.money.toLocaleString("vi-VN"),
                        id: res.data.data[0]._id
                    });
                });
                axios.get(`${process.env.REACT_APP_API_URL}/greedy/bxh`, {}).then((res) => {
                    setBXH(res.data.data);
                });
            }
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [dulieunhap]);
    useEffect(() => {
        if (lastJsonMessage !== null) {
            const { type, data, user } = lastJsonMessage;
            if (type === "choose_greedy_fail" && user == profile?.username) {
                swal("Thất bại", data.message, "error");
            }
            if (type === "choose_greedy_success" && user == profile?.username) {
                getProfile();
            }
        }
    }, [lastJsonMessage]);
    function showKQ() {
        axios.get(`${process.env.REACT_APP_API_URL}/history/historyus`, { params: { keyword: "Greedy", limit: "10" } }).then((res) => {
            if (
                Number(res.data.data[0].id_bet.id_bet) === Number(bet.id_bet) - 1
            ) {
                if (res.data.data[0].status_bet === "Win") {
                    setWIN(res.data.data[0]);
                } else if (res.data.data[0].status_bet === "Lose") {
                    setLOSE(res.data.data[0]);
                } else if (res.data.data[0].status_bet === "Pending") {
                    console.log("reroll");
                    showKQ();
                    return;
                }
                setHistoryGame(res.data.data);
                getProfile();
            }
            setKQ(res.data.data[0]);
        });
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            showKQ();
        }, 1500);

        return () => {
            clearInterval(timer);
        };
    }, [dulieunhap]);

    useEffect(() => {
        let curTime_second = Math.floor(60 - (date - dulieunhap) / 1000);

        let myTimeout;

        if (currentMinute === dulieunhap.getMinutes() && currentSecond === dulieunhap.getSeconds()) {
            setStart(true);
            setSecond(second - 1);
            return () => {
                clearTimeout(myTimeout);
            };
        } else if (curTime_second < 60 && curTime_second >= 0) {
            setSecond(curTime_second % 60);
            setMinute((curTime_second - (curTime_second % 60)) / 60);
            setStart(true);
            return () => {
                clearTimeout(myTimeout);
            };
        } else {
            //cập nhật thời gian hiện tại 0.5s/lần
            myTimeout = setTimeout(() => {
                setUpdate(update + 1);
            }, 500);
        }
    }, [update, dulieunhap]);

    useEffect(() => {
        let curTime_second = Math.floor(60 - (date - dulieunhap) / 1000);
        let myTimeout = 0;
        if (start) {
            setSecond(curTime_second % 60);
            setMinute(Math.floor(curTime_second / 60));

            if (curTime_second > 60 || curTime_second <= 0) {
                setStart(false);
                setMinute(1);
                setSecond(0);
                return () => {
                    clearTimeout(myTimeout);
                };
            }
            myTimeout = setTimeout(() => {
                setSecond(second - 1);
            }, 1000);
        }
        return () => {
            clearTimeout(myTimeout);
        };
    }, [second, start, dulieunhap]);

    useEffect(() => {
        let swalInst;
        const showAlert = async (data) => {
            swalInst = swal({
                title: "Thông báo hệ thống",
                text: ` Chúc mừng quý khách đã may mắn được nhận ${data?.money.toLocaleString("vi-VN")} vào tài khoản`,
                icon: "info",
                buttons: {
                    submit: "Tôi đã hiểu"
                }
            });
            const result = await swalInst;

            switch (result) {
                case "submit":
                    axios.post(`${process.env.REACT_APP_API_URL}/notification/seen`, {
                        id: data.id
                    });
                    break;
                default:
            }

            setVisible(false);
        };
        if (isVisible) {
            showAlert(isVisible);
        }
    }, [isVisible]);

    const [isOpen, setIsOpen] = useState(false);
    const openPopup = () => {
        setIsOpen(true);
    };
    const closePopup = () => {
        setIsOpen(false);
    };
    const [isOpen1, setIsOpen1] = useState(false);
    const openPopup1 = () => {
        getHistoryBet();
        setIsOpen1(true);
    };
    const closePopup1 = () => {
        setIsOpen1(false);
    };
    const getAllChoose = () => {
        return Object.entries(counters)
            .filter(([key, value]) => value > 0)
            .map(([key]) => Number(key.replace(/^s/, "")) + 1);
    };
    function onChoose(num) {
        if (setting?.greedy?.open != 1) {
            swal("Thất bại", "Trò chơi đang bảo trì", "error");
            return;
        }
        if (minute === 0 && second < 10) {
            swal("Thất bại", "Vui lòng đợi phiên sau", "error");
            return;
        }
        if (newMoney == 0 || newMoney == null) {
            swal("Thất bại", "Bạn chưa chọn tiền", "error");
            return;
        }
        /*if (calculateTotal() + newMoney * 1000 > profile?.money || profile?.money == 0) {
            swal("Thất bại", "Số dư không đủ", "error");
            return;
        }*/
        if (newMoney * 1000 > profile?.money || profile?.money == 0) {
            swal("Thất bại", "Số dư không đủ", "error");
            return;
        }
        setActive(num.toString());
        inCrease("s" + (Number(num) - 1).toString(), newMoney);
    }

    const handleOptionClick = (option) => {
        setActiveOption(option);
        setNewMoney(Number(option));
    };
    const inCrease = (key, money) => {
        setCounters((prevCounters) => {
            const newCounters = {
                ...prevCounters,
                [key]: prevCounters[key] + Number(money)
            };
            const allChoose = Object.entries(newCounters)
                .filter(([key, value]) => value > 0)
                .map(([key]) => Number(key.replace(/^s/, "")) + 1);
            const values = Object.values(newCounters);
            const total = values.reduce((acc, curr) => acc + curr, 0);
            const formData = {
                user: profile?._id,
                result: allChoose.join(" "),
                id: bet,
                money: total * 1000,
                detail: newCounters,
                newmoney: newMoney * 1000
            };
            sendJsonMessage({ type: "choose_greedy", data: formData });
            return newCounters;
        });
    };

    const calculateTotal = () => {
        const values = Object.values(counters);
        const total = values.reduce((acc, curr) => acc + curr, 0);
        return total * 1000;
    };
    /*const onSubmit = (e) => {
        const formData = {
            result: getAllChoose().join(" "),
            id: bet?._id,
            money: calculateTotal(),
            detail: counters,
            lot: bet.id_bet
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/greedy/choose`, formData)
            .then((res) => {
                setNewMoney(0);
                setActiveOption(null);
                setActive(null);
                setCounters({ s0: 0, s1: 0, s2: 0, s3: 0, s4: 0, s5: 0, s6: 0, s7: 0, s8: 0, s9: 0 });
                getProfile();
                getHistoryBet();
            })
            .catch((err) => swal("Đã xảy ra lỗi", "Vui lòng tải lại trang", "error"));
    };*/

    function getHistoryBet() {
        axios
            .get(`${process.env.REACT_APP_API_URL}/history/historyus`, { params: { keyword: "Greedy" } })
            .then((res) => {
                setHistoryGame(res.data.data);
            })
            .catch((err) => function () {});
    }
    useEffect(() => {
        if (historyGame != null && second > 0 && second < 50) {
            if (historyGame[0]?.lot === bet?.id_bet) {
                setCounters(historyGame[0]?.detail);
            }
        }
    }, [historyGame]);

    useEffect(() => {
        let timeoutId;
        if (KQ) {
            timeoutId = setTimeout(() => {
                setKQ(null);
                setWIN(null);
                setLOSE(null);
                /*
                setNewMoney(0);
                setActiveOption(null);
                setActive(null);
                setCounters({ s0: 0, s1: 0, s2: 0, s3: 0, s4: 0, s5: 0, s6: 0, s7: 0, s8: 0, s9: 0 });
                getProfile();
                getHistoryBet();
                axios.get(`${process.env.REACT_APP_API_URL}/greedy/bxh`, {}).then((res) => {
                    setBXH(res.data.data);
                });*/
            }, 9500);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [KQ]);

    const [countdown, setCountdown] = useState(false);
    const runEffect = () => {
        let intervalId;
        setCountdown(true);
        setKQ(null);
        setWIN(null);
        setLOSE(null);
        intervalId = setInterval(() => {
            setActive((prevIndex) => (prevIndex % 8) + 1);
        }, 300);
        setTimeout(function () {
            clearInterval(intervalId);
            setActive(null);
            setCountdown(false);
            setCounters({ s0: 0, s1: 0, s2: 0, s3: 0, s4: 0, s5: 0, s6: 0, s7: 0, s8: 0, s9: 0 });
        }, 10000);
    };
    useEffect(() => {
        if (minute === 0 && second === 10) {
            runEffect();
            sendJsonMessage({ type: "roll_greedy", data: { user: profile?._id } });
            //calculateTotal() > 0 && onSubmit();
        }
    }, [minute, second]);

    return (
        <>
            <div className="main">
                <Header profile={profile} />
                <div className="greedy-scale">
                    <div className="greedy-game">
                        <div className="layer-title">
                            <div className="box-title">
                                <div className="item">
                                    <div className="item-icon" onClick={() => navigate("/")}>
                                        <HomeOutlinedIcon sx={{ fontSize: "20px" }} />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-icon" onClick={openPopup}>
                                        <QuestionMarkOutlinedIcon sx={{ fontSize: "18px" }} />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-icon" onClick={openPopup1}>
                                        <RestoreIcon sx={{ fontSize: "18px" }} />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-icon" onClick={() => window.location.reload()}>
                                        <CachedOutlinedIcon sx={{ fontSize: "18px" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="round-game">Phiên {bet ? bet?.id_bet : "-----"}</div>
                        </div>
                        <div className="middle-img">
                            <img alt="" src={require("./images/midleBigo.b75cb9d.png")} />
                            <div className="middle-icon">
                                <img alt="" src={require("./images/midBigo.fbdc9ff.png")} />
                                <span className="text">
                                    Thời gian chọn{" "}
                                    {countdown == false && (
                                        <b>
                                            {minute
                                                .toString()
                                                .split("")
                                                .map((item, index) => (
                                                    <span key={index}>{item}</span>
                                                ))}
                                            <span>:</span>
                                            {second < 10 ? <span>0</span> : ""}
                                            {second
                                                .toString()
                                                .split("")
                                                .map((item, index) => (
                                                    <span key={index}>{item}</span>
                                                ))}
                                        </b>
                                    )}
                                </span>
                            </div>
                            {countdown == true && (
                                <div className="backdrop-countdown">
                                    <div style={{ fontWeight: "Bold", color: "#fbcf38" }}>
                                        {second
                                            .toString()
                                            .split("")
                                            .map((item, index) => (
                                                <span key={index}>{item}</span>
                                            ))}
                                    </div>
                                </div>
                            )}
                            <div className={`icon icon-1 ${active == "1" ? "active" : ""}`} onClick={(e) => onChoose("1")}>
                                <span className="image">
                                    <img alt="" src={require("./images/food_1.png")} />
                                </span>
                                <span className="text">
                                    win {setting?.greedy?.thuong1} times
                                    {counters.s0 > 0 && (
                                        <span>
                                            <img src={require("./images/1coin.07a8551.png")} />
                                            {counters.s0.toLocaleString("vi-VN")}
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className={`icon icon-2 ${active == "2" ? "active" : ""}`} onClick={(e) => onChoose("2")}>
                                <span className="image">
                                    <img alt="" src={require("./images/food_2.png")} />
                                </span>
                                <span className="text">
                                    win {setting?.greedy?.thuong2} times
                                    {counters.s1 > 0 && (
                                        <span>
                                            <img src={require("./images/1coin.07a8551.png")} />
                                            {counters.s1.toLocaleString("vi-VN")}
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className={`icon icon-3 ${active == "3" ? "active" : ""}`} onClick={(e) => onChoose("3")}>
                                <span className="image">
                                    <img alt="" src={require("./images/food_3.png")} />
                                </span>
                                <span className="text">
                                    win {setting?.greedy?.thuong3} times
                                    {counters.s2 > 0 && (
                                        <span>
                                            <img src={require("./images/1coin.07a8551.png")} />
                                            {counters.s2.toLocaleString("vi-VN")}
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className={`icon icon-4 ${active == "4" ? "active" : ""}`} onClick={(e) => onChoose("4")}>
                                <span className="image">
                                    <img alt="" src={require("./images/food_4.png")} />
                                </span>
                                <span className="text">
                                    win {setting?.greedy?.thuong4} times
                                    {counters.s3 > 0 && (
                                        <span>
                                            <img src={require("./images/1coin.07a8551.png")} />
                                            {counters.s3.toLocaleString("vi-VN")}
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className={`icon icon-5 ${active == "5" ? "active" : ""}`} onClick={(e) => onChoose("5")}>
                                <span className="image">
                                    <img alt="" src={require("./images/food_5.png")} />
                                </span>
                                <span className="text">
                                    win {setting?.greedy?.thuong5} times
                                    {counters.s4 > 0 && (
                                        <span>
                                            <img src={require("./images/1coin.07a8551.png")} />
                                            {counters.s4.toLocaleString("vi-VN")}
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className={`icon icon-6 ${active == "6" ? "active" : ""}`} onClick={(e) => onChoose("6")}>
                                <span className="image">
                                    <img alt="" src={require("./images/food_6.png")} />
                                </span>
                                <span className="text">
                                    win {setting?.greedy?.thuong6} times
                                    {counters.s5 > 0 && (
                                        <span>
                                            <img src={require("./images/1coin.07a8551.png")} />
                                            {counters.s5.toLocaleString("vi-VN")}
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className={`icon icon-7 ${active == "7" ? "active" : ""}`} onClick={(e) => onChoose("7")}>
                                <span className="image">
                                    <img alt="" src={require("./images/food_7.png")} />
                                </span>
                                <span className="text">
                                    win {setting?.greedy?.thuong7} times
                                    {counters.s6 > 0 && (
                                        <span>
                                            <img src={require("./images/1coin.07a8551.png")} />
                                            {counters.s6.toLocaleString("vi-VN")}
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className={`icon icon-8 ${active == "8" ? "active" : ""}`} onClick={(e) => onChoose("8")}>
                                <span className="image">
                                    <img alt="" src={require("./images/food_8.png")} />
                                </span>
                                <span className="text">
                                    win {setting?.greedy?.thuong8} times
                                    {counters.s7 > 0 && (
                                        <span>
                                            <img src={require("./images/1coin.07a8551.png")} />
                                            {counters.s7.toLocaleString("vi-VN")}
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className={`icon icon-mini icon-9 ${active == "9" ? "active" : ""}`} onClick={(e) => onChoose("9")}>
                                <span className="image">
                                    <img alt="" src={require("./images/food_9.png")} />
                                </span>
                                <span className="text">
                                    {setting?.greedy?.thuong9}x
                                    {counters.s8 > 0 && (
                                        <span>
                                            <img src={require("./images/1coin.07a8551.png")} />
                                            {counters.s8.toLocaleString("vi-VN")}
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className={`icon icon-mini icon-10 ${active == "10" ? "active" : ""}`} onClick={(e) => onChoose("10")}>
                                <span className="image">
                                    <img alt="" src={require("./images/food_10.png")} />
                                </span>
                                <span className="text">
                                    {setting?.greedy?.thuong10}x
                                    {counters.s9 > 0 && (
                                        <span>
                                            <img src={require("./images/1coin.07a8551.png")} />
                                            {counters.s9.toLocaleString("vi-VN")}
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className={`icon icon-mini`} style={{ border: "0", top: "432px", marginLeft: "110px" }}>
                                <img alt="" src={require("./images/pizza.21c3b24.png")} style={{ height: "50px" }} />
                            </div>
                            <div className={`icon icon-mini`} style={{ border: "0", top: "432px", marginLeft: "-110px" }}>
                                <img alt="" src={require("./images/salad.a178f32.png")} style={{ height: "50px" }} />
                            </div>
                            <div className="bottom-select">
                                <div className={`bottom-choose ${activeOption == "10" ? "active" : ""}`} onClick={() => handleOptionClick("10")}>
                                    <div className="icon-coin">10</div>
                                </div>
                                <div className={`bottom-choose ${activeOption == "50" ? "active" : ""}`} onClick={() => handleOptionClick("50")}>
                                    <div className="icon-coin">50</div>
                                </div>
                                <div className={`bottom-choose ${activeOption == "100" ? "active" : ""}`} onClick={() => handleOptionClick("100")}>
                                    <div className="icon-coin">100</div>
                                </div>
                                <div className={`bottom-choose ${activeOption == "200" ? "active" : ""}`} onClick={() => handleOptionClick("200")}>
                                    <div className="icon-coin">200</div>
                                </div>
                                <div className={`bottom-choose ${activeOption == "500" ? "active" : ""}`} onClick={() => handleOptionClick("500")}>
                                    <div className="icon-coin">500</div>
                                </div>
                                <div className={`bottom-choose ${activeOption == "1000" ? "active" : ""}`} onClick={() => handleOptionClick("1000")}>
                                    <div className="icon-coin">1000</div>
                                </div>
                                <div className={`bottom-choose ${activeOption == "2000" ? "active" : ""}`} onClick={() => handleOptionClick("2000")}>
                                    <div className="icon-coin">2000</div>
                                </div>
                                <div className={`bottom-choose ${activeOption == "5000" ? "active" : ""}`} onClick={() => handleOptionClick("5000")}>
                                    <div className="icon-coin">5000</div>
                                </div>
                                <div className={`bottom-choose ${activeOption == "10000" ? "active" : ""}`} onClick={() => handleOptionClick("10000")}>
                                    <div className="icon-coin">10k</div>
                                </div>
                                <div className={`bottom-choose ${activeOption == "20000" ? "active" : ""}`} onClick={() => handleOptionClick("20000")}>
                                    <div className="icon-coin">20k</div>
                                </div>
                            </div>
                        </div>
                        <div className="greedy-stats">
                            Đã chọn <b>{getAllChoose().length}</b>. Tổng tiền cược <b>{calculateTotal().toLocaleString("vi-VN")}</b>
                        </div>
                        <div className="greedy-result-list">
                            <div>Kết quả:</div>
                            <div>
                                {total &&
                                    total.map((item, index) => (
                                        <>{index < 9 && item?.id_bet != bet?.id_bet && <img key={index} alt="" src={require(`./images/food_${item?.result}.png`)} />}</>
                                    ))}
                            </div>
                        </div>
                        <div className="bangxephang">
                            <div>
                                <img src={require("./images/rank_1.44d42cc.png")} />
                            </div>
                            <div>
                                <h3>BXH THU NHẬP HÔM NAY</h3>
                                <h4>
                                    <img src={require("./images/1diamond.93d10cf.png")} width="15" style={{ marginRight: "5px" }} />
                                    {bxh && bxh[0]?.success?.toLocaleString("vi-VN")}
                                </h4>
                            </div>
                            <div>
                                <button onClick={() => setViewBXH(true)}>XEM TẤT CẢ</button>
                            </div>
                        </div>
                    </div>
                </div>
                {viewBXH == true && (
                    <div className="popup-backdrop">
                        <div className="popup-bxh">
                            <div className="popup-bxh-title">BXH Thu Nhập Hôm Nay</div>
                            <div className="popup-bxh-content">
                                {bxh.length > 0
                                    ? bxh.map((item, index) => (
                                          <div className="popup-bxh-detail">
                                              <div>
                                                  {index == 0 ? (
                                                      <img src={require("./images/rank_1.44d42cc.png")} width="30" style={{ marginRight: "10px" }} />
                                                  ) : index == 1 ? (
                                                      <img src={require("./images/rank_2.4019bf3.png")} width="30" style={{ marginRight: "10px" }} />
                                                  ) : index == 2 ? (
                                                      <img src={require("./images/rank_3.f72353c.png")} width="30" style={{ marginRight: "10px" }} />
                                                  ) : (
                                                      <span>{index + 1}</span>
                                                  )}
                                              </div>
                                              <div>
                                                  <h5>{item?.username}</h5>
                                              </div>
                                              <div>
                                                  <img src={require("./images/1diamond.93d10cf.png")} width="20" />
                                                  <strong>{item?.success?.toLocaleString("vi-VN")}</strong>
                                              </div>
                                          </div>
                                      ))
                                    : null}
                            </div>
                            <div onClick={() => setViewBXH(false)} className="popup-bxh-close">
                                &times;
                            </div>
                        </div>
                    </div>
                )}
                {isOpen && (
                    <div className="popup-backdrop">
                        <div className="popup-main">
                            <div className="popup-header">Hướng dẫn cách chơi</div>
                            <div className="popup-content">
                                1. Chọn số lượng vàng, xong lựa chọn tiếp loại mà bạn thích là có thể tham gia trò chơi;
                                <div className="br" />
                                2. Mỗi phiên có 60 giây để lựa chọn, sau khi hết thời gian sẽ mở thưởng.
                                <div className="br" />
                                3. Nếu như kết quả mở thưởng giống với loại mà bạn chọn thì bạn có thể nhận được phần thưởng bội số vàng tương ứng;
                                <div className="br" />
                                4. Mỗi vàng tương ứng với 1000 điểm. 10 vàng tương ứng 10.000 điểm;
                                <div className="br" />
                                5. Gói tuỳ chọn và đề cử hot có thể lựa chọn đặt cược liên tục hoặc đặt cược một lán.
                                <div className="br" />
                                6. Quỹ thưởng càng lớn, cơ hội xuất hiện pizza hoặc salad sẽ càng tăng lên.
                                <div className="br" />
                                7. Nếu salad xuất hiện, tất cả những người chơi chọn rau sẽ thắng; nếu pizza xuất hiện, tất cả những người chơi chọn thịt sẽ thẳng.
                                <div className="br" />
                                8. Đặt cược rổ rau, bất cứ vật phẩm rau nào mở thưởng thì sẽ chiến thắng, đặt cược đĩa thịt, bất cứ vật phẩm thịt nào mở thưởng thì sẽ chiến thẳng.
                                <div className="br" />
                                9. LƯU Ý: Cược vào Rõ Rau hoặc Đĩa Thịt sẽ không thẳng nếu vật phẩm rút thăm là Salad hoặc Pizza.
                                <div className="br" />
                                10. Kết quả cuối cùng do hệ thống tự động chọn ngẫu nhiên
                            </div>
                            <button onClick={closePopup} className="popup-close">
                                Đóng
                            </button>
                        </div>
                    </div>
                )}

                {isShow === true && ls.status_bet !== "Pending" ? (
                    <>
                        <div className="modal" style={{ zIndex: "9999999" }}>
                            <div className="modaloverlay"></div>
                            <div className="modalbody">
                                <div>
                                    <div className="modalinner" style={{ padding: "10px 15px" }}>
                                        <div className="modalheader" style={{ padding: "10px 0 20px" }}>
                                            Chi tiết cược
                                        </div>

                                        {ls?.id_bet?.id_bet ? (
                                            <>
                                                <div className="lsgd-table">
                                                    <div>Trò chơi</div>
                                                    <div>Greedy</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Phiên</div>
                                                    <div>{ls?.id_bet?.id_bet}</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Thời gian</div>
                                                    <div>{formatDate(new Date(ls.createdAt))}</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Đặt cược</div>
                                                    <div>
                                                        {Object.entries(ls.detail).map(
                                                            ([key, value]) =>
                                                                value > 0 && (
                                                                    <div key={key}>
                                                                        <img
                                                                            alt=""
                                                                            src={require(`./images/food_${Number(key.slice(-1)) + 1}.png`)}
                                                                            style={{ height: "25px", marginRight: "5px", position: "relative", top: "5px" }}
                                                                        />
                                                                        : {(value * 1000).toLocaleString("vi-VN")}
                                                                    </div>
                                                                )
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Tổng Cược</div>
                                                    <div>{Number(ls?.money).toLocaleString("vi-VN")} </div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Tổng thắng</div>
                                                    <div>{Number(ls?.moneythang).toLocaleString("vi-VN")} </div>
                                                </div>
                                                <h3
                                                    style={{
                                                        fontSize: "0.4rem",
                                                        margin: "20px 0 10px"
                                                    }}>
                                                    Kết quả phiên {ls?.id_bet?.id_bet}
                                                </h3>
                                                <div
                                                    className="history_xucsac"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}>
                                                    <img alt="" src={require(`./images/food_${ls.id_bet.result}.png`)} style={{ height: "45px" }} />
                                                </div>
                                            </>
                                        ) : null}
                                        <div>
                                            <div className="modalformcontrols">
                                                <button
                                                    onClick={() => setShow(false)}
                                                    className="popup-close"
                                                    style={{
                                                        background: "#0064ff",
                                                        boxShadow: "none",
                                                        textShadow: "none"
                                                    }}>
                                                    ĐÓNG
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}

                {KQ && total && second < 59 && second > 50 && (
                    <div className="popup-backdrop">
                        <div className="greedy-bangketqua">
                            <div className="greedy-ketqua">
                                <div className="greedy-ketqua-content">
                                    <div className="greedy-award">
                                        <img alt="" src={require(`./images/award_${total ? total[0]?.result : "bigo"}.png`)} style={{ height: "80px", marginTop: "-38px" }} />
                                    </div>
                                    <div style={{ fontSize: "17px" }}>
                                        Kết quả phiên <b>{total[0]?.id_bet}</b> :
                                        <img
                                            alt=""
                                            src={require(`./images/food_${total[0]?.result}.png`)}
                                            style={{ height: "35px", marginLeft: "5px", position: "relative", top: "10px" }}
                                        />
                                        {WIN != null && (
                                            <>
                                                <div style={{ marginTop: "15px" }}>
                                                    Chiến thắng:{" "}
                                                    <img
                                                        alt=""
                                                        src={require(`./images/1diamond.93d10cf.png`)}
                                                        style={{ height: "20px", margin: "0 5px", position: "relative", top: "3px" }}
                                                    />
                                                    {WIN?.moneythang.toLocaleString("vi-VN")}
                                                </div>
                                                <div style={{ marginTop: "20px", fontSize: "14px" }}>
                                                    Lựa chọn phiên này của bạn:{" "}
                                                    <img
                                                        alt=""
                                                        src={require(`./images/1coin.07a8551.png`)}
                                                        style={{ height: "20px", margin: "0 5px", position: "relative", top: "3px" }}
                                                    />
                                                    {WIN?.money.toLocaleString("vi-VN")}
                                                </div>
                                            </>
                                        )}
                                        {LOSE != null && (
                                            <>
                                                <div style={{ marginTop: "15px" }}>Bạn đã thua phiên này.</div>
                                                <div style={{ marginTop: "20px", fontSize: "14px" }}>
                                                    Lựa chọn phiên này của bạn:{" "}
                                                    <img
                                                        alt=""
                                                        src={require(`./images/1coin.07a8551.png`)}
                                                        style={{ height: "20px", margin: "0 5px", position: "relative", top: "3px" }}
                                                    />
                                                    {LOSE?.money.toLocaleString("vi-VN")}
                                                </div>
                                            </>
                                        )}
                                        {WIN == null && LOSE == null && <div style={{ marginTop: "15px" }}>Bạn chưa tham gia phiên này.</div>}
                                    </div>
                                </div>
                                <div
                                    onClick={() => {
                                        setKQ(null);
                                        setWIN(null);
                                        setLOSE(null);
                                    }}
                                    className="greedy-bangketqua-close">
                                    &times;
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {isOpen1 && (
                    <div className="popup-backdrop">
                        <div className="popup-main">
                            <div className="popup-header" style={{ background: "#fbcf38" }}>
                                Lịch Sử Tham Gia
                            </div>
                            <div className="popup-content">
                                {historyGame != null ? (
                                    <div className="content-history award_tb">
                                        {historyGame?.map((item, key) => (
                                            <>
                                                {item.sanh === "Greedy" ? (
                                                    <div
                                                        key={key}
                                                        className="item_inner"
                                                        onClick={() => {
                                                            setLs(item);
                                                            setShow(true);
                                                        }}>
                                                        <div className="item_history">
                                                            <div className="title_item_history">
                                                                <span className="sanh">{item.sanh}</span>
                                                                <span
                                                                    className={`type_state ${
                                                                        item.status_bet === "Pending" ? "pending" : item.status_bet === "Win" ? "win" : "lose"
                                                                    }`}>
                                                                    {item.status_bet}
                                                                </span>
                                                            </div>
                                                            <div className="id_history_sanh">Phiên cược: {item?.id_bet?.id_bet ? item?.id_bet?.id_bet : item?.id_bet}</div>
                                                            <div className="id_history_sanh">
                                                                {(item?.state).split(" ").map((r, i) => (
                                                                    <img key={i} alt="" src={require(`./images/food_${r}.png`)} style={{ height: "25px", marginRight: "5px" }} />
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className="money_history">
                                                            <span className="money">{Number(item?.money).toLocaleString("vi-VN")}</span>
                                                            <div className="time_choose">{formatDate(new Date(item?.createdAt))}</div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </>
                                        ))}
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            <button
                                onClick={closePopup1}
                                className="popup-close"
                                style={{
                                    background: "#fbcf38",
                                    boxShadow: "none",
                                    textShadow: "none"
                                }}>
                                Đóng
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
export default Greedy;
