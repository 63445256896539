import React, { useEffect, useState } from "react";
import { GetNameChoose } from "../../../funcUtils";
import axios from "axios";

const BXH = ({ isOpen, closePopup, setData }) => {
    const [top, setTop] = useState(null);
    const [isShow, setShow] = useState(false);
    const [view, setView] = useState(null);

    useEffect(() => {
        if (isOpen) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/Xoso3/bxh`, {})
                .then((res) => {
                    setTop(res.data.data);
                })
                .catch((err) => {});
        }
    }, [isOpen]);
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    return (
        <>
            {isOpen && (
                <div className="popup-backdrop">
                    <div className="popup-bxh bxh">
                        <div className="popup-bxh-title">BXH Thu Nhập Hôm Nay</div>
                        <div className="popup-bxh-content">
                            {top ? (
                                top.map((item, index) => (
                                    <>
                                        {item?.success > 0 && (
                                            <div className="popup-bxh-detail">
                                                <div>
                                                    {index == 0 ? (
                                                        <img src={require("../Greedy/images/rank_1.44d42cc.png")} width="30" style={{ marginRight: "10px" }} />
                                                    ) : index == 1 ? (
                                                        <img src={require("../Greedy/images/rank_2.4019bf3.png")} width="30" style={{ marginRight: "10px" }} />
                                                    ) : index == 2 ? (
                                                        <img src={require("../Greedy/images/rank_3.f72353c.png")} width="30" style={{ marginRight: "10px" }} />
                                                    ) : (
                                                        <span>{index + 1}</span>
                                                    )}
                                                </div>
                                                <div>
                                                    <h5>{item?.username}</h5>
                                                </div>
                                                <div>
                                                    <img src={require("../Greedy/images/1diamond.93d10cf.png")} width="20" />
                                                    <strong>{item?.success?.toLocaleString('vi-VN')}</strong>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))
                            ) : (
                                <div style={{ fontSize: "18px" }}>Chưa có dữ liệu</div>
                            )}
                        </div>
                        <div onClick={closePopup} className="popup-bxh-close">
                            &times;
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BXH;
