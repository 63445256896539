import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Register() {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    useEffect(() => {
        if (login) {
            navigate("/");
        }
    }, []);
    const onSubmit = (data) => {
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6 || !regex.test(data.username)) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự và không chứa kí tự đặc biệt"
            });
        }
        if (data.username.length > 11) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối đa 11 kí tự và không chứa kí tự đặc biệt"
            });
        }

        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu tối thiểu 6 kí tự"
            });
        }
        if (data.password !== data.ippassword) {
            setError("ippassword", {
                type: "minLength",
                message: "Vui lòng nhập lại mật khẩu"
            });
        }

        if (data.password.length < 6 || data.username.length > 11 || data.username.length < 6 || data.password !== data.ippassword || !regex.test(data.username)) {
            return;
        }
        const formData = {
            username: data.username.toLowerCase(),
            password: data.password,
            code: data.code === "" ? "admin" : data.code,
            sdt: data.sdt
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/register`, formData)
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng ký thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => navigate("/login"));
            })
            .catch((err) => {
                setErr(err.response.data.message ? err.response.data.message : "Đã có lỗi xảy ra!");
            });
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [showPassword1, setShowPassword1] = useState(false);
    const toggleShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };
    return (
        <>
            <div className="login">
                <form className="form-lg" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "200px" }}>
                    <img alt="" src="/upload/login.png" className="logologin" />
                    <h1>Đăng Ký</h1>
                    <div className="inputs">
                        <div className="input">
                            <input type="text" {...register("username", { required: true })} className="ip-lg" placeholder="Tên đăng nhập" />
                            {errors.username ? <p>{errors.username.message}</p> : null}
                        </div>
                        <div className="input">
                            <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Mật khẩu" />
                            <div onClick={toggleShowPassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</div>
                            {errors.password ? <p>{errors.password.message}</p> : null}
                        </div>
                        <div className="input">
                            <input type={showPassword1 ? "text" : "password"} className="ip-lg" {...register("ippassword", { required: true })} placeholder="Nhập lại mật khẩu" />
                            <div onClick={toggleShowPassword1}>{showPassword1 ? <Visibility /> : <VisibilityOff />}</div>
                            {errors.ippassword ? <p>{errors.ippassword.message}</p> : null}
                        </div>
                        <div className="input">
                            <input type="code" className="ip-lg" {...register("code")} placeholder="Nhập mã giới thiệu" />
                        </div>
                        <div className="input">
                            <input pattern="(\+84|0)\d{9,10}" type="sdt" className="ip-lg" {...register("sdt", { required: true })} placeholder="Nhập số điện thoại" />
                            {err ? <p>{err}</p> : null}
                        </div>
                    </div>
                    <button type="submit" className="btn-lg">
                        Đăng ký
                    </button>
                    <p className="p-lg">
                        Đã có tài khoản?{" "}
                        <Link className="a-lg" to="/login" style={{ fontWeight: "bold" }}>
                            Đăng nhập
                        </Link>
                    </p>
                    <p style={{ color: "#fff" }}>
                        Cần hỗ trợ ?{" "}
                        <Link className="a-lg" to="/service" style={{ color: "#fce406" }}>
                            Liên hệ CSKH
                        </Link>
                    </p>
                </form>
            </div>
        </>
    );
}
export default Register;
